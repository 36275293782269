import './App.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactComponent as DownloadIcon } from './icons/download.svg';

const getImageUrl = async () => {
    const random = Math.round(Math.random() * 1000);
    const post = await axios.get('https://www.randomkittykitten.com/random?random=' + random);
    return post.data.url;
}

function App() {
    const preloadCount = 10;
    const [seenCount, setSeenCount] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);

    const nextImage = () => {
        setCurrentImageIndex(previousIndex => {
            if (previousIndex === null) {
                return 0;
            }
            return Math.min(previousIndex + 1, imageUrls.length);
        });
        preload();
    }

    const previousImage = () => {
        setCurrentImageIndex(previousIndex => Math.max(0, previousIndex - 1));
    }

    const preload = () => {
        const toPreload = Math.max(0, (currentImageIndex + preloadCount) - imageUrls.length);
        for (let i = 0; i < toPreload; i += 1) {
            getImageUrl().then(url => {
                setImageUrls(previousImageUrls => [...previousImageUrls, url]);
            });
        }
    }

    const download = () => {
        const downloadButton = document.getElementsByClassName('download')[0];
        downloadButton.click();
    }

    useEffect(() => {
        setSeenCount(previousSeenCount => {
            if (currentImageIndex === null) {
                return 0;
            }
            return Math.max(previousSeenCount, (currentImageIndex + 1));
        });
    }, [currentImageIndex]);

    useEffect(() => {
        if (currentImageIndex === null && imageUrls.length > 0) {
            nextImage();
        }
    });

    useEffect(() => {
        preload();
        const newImageHandler = (event) => {
            if (event.key === ' ') {
                nextImage();
            }
        };
        const downloadHandler = (event) => {
            if (event.key === 'd') {
                download();
            }
        };
        document.addEventListener('keyup', newImageHandler);
        document.addEventListener('keyup', downloadHandler);
        return () => {
            document.removeEventListener('keyup', newImageHandler);
            document.removeEventListener('keyup', downloadHandler);
        };
        // eslint-disable-next-line
    }, [nextImage]);

    const urlsToPreload = imageUrls.slice(currentImageIndex + 1, currentImageIndex + 5)

    return (
        <div className="App">
            {(currentImageIndex === null) ? (
                <span>Loading...</span>
            ) : (
                <div className="imageContainer">
                    <img src={imageUrls[currentImageIndex]} className="kitten" alt="kitten" />
                    <a className="download" href={imageUrls[currentImageIndex]} download target="_blank" rel="noreferrer">
                        <DownloadIcon />
                    </a>
                </div>
            )}
            <span className="separator"></span>
            <strong>You have seen {seenCount} images of kitty-kitty-kitten-catty-cat so far</strong>
            <div className="buttons">
                {(currentImageIndex > 0) && <button className="previous" onClick={previousImage}>Wait, come back !</button>}
                <button className="more" onClick={nextImage}>One more !</button>
            </div>
            <span>( Or press SPACE for a kitty-keyboard experiment, and [D] for download )</span>
            {urlsToPreload.map(url => (
                <img className="preload" src={url} alt="preload" />
            ))}
        </div >
    );
}

export default App;
